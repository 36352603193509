<template>
  <b-card body>
    <div class="d-flex justify-between">
      <div class="d-flex w-50 align-items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black font-semibold text-2xl">
          Stok Gudang
        </div>
      </div>
      <div
        v-if="items"
        class="w-25"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="field-search-for-tbl"
            v-model="search"
            placeholder="Cari nama partner"
            @input="searching()"
          />
        </b-input-group>
      </div>
    </div>
    <div class="mt-[16px]">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <div
          v-if="items"
          class="space-y-5"
        >
          <div
            v-for="item in items"
            :key="item.id"
            class="border rounded-lg p-1"
          >
            <div class="mb-1 flex gap-x-4">
              <b-img
                v-if="item.image_logo_url !== null"
                class="object-cover"
                rounded="circle"
                :src="item.image_logo_url"
                style="width: 50px; height: 50px"
                alt="logo"
              />
              <b-img
                v-else
                class="object-cover"
                rounded="circle"
                src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
                style="width: 50px; height: 50px"
                alt="logo"
              />
              <b-col class="align-self-center text-[#222222]">
                <div class="font-semibold">
                  {{ item.name }}
                </div>
                <div>{{ formatDate(item.approved_date) }}</div>
              </b-col>
            </div>
            <b-row class="font-normal text-black">
              <b-col
                md="2"
                lg="2"
              >
                <p>Sektor Bisnis</p>
                <p>Tipe Bisnis</p>
              </b-col>
              <b-col
                md="4"
                lg="5"
              >
                <p>{{ item.business_sector === null ? '-' : item.business_sector }}</p>
                <p>{{ item.business_type === null ? '-' : item.business_type }}</p>
              </b-col>
              <b-col
                md="2"
                lg="2"
              >
                <p>Jumlah Produk</p>
                <p>Total Terjual</p>
              </b-col>
              <b-col
                md="1"
                lg="1"
              >
                <p>{{ item.product_total }}</p>
                <p>{{ item.total_sold }}</p>
              </b-col>
              <b-col
                md="2"
                lg="2"
              >
                <div class="-mt-7">
                  <b-button
                    variant="primary"
                    style="width: 135px"
                    @click="stock(item)"
                  >Lihat Stock
                  </b-button>
                </div>
                <div class="mt-[12px]">
                  <b-button
                    variant="outline-primary"
                    style="width: 135px"
                    @click="chatButton(item.number_phone)"
                  >Chat Partner
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <div
          v-else
          class="text-center h-[300px] d-flex justify-content-center align-items-center"
        >
          {{ text }}
        </div>
      </b-overlay>
    </div>
  </b-card>
</template>
<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { WAREHOUSE_PARTNER } from '@/provider/url'
import moment from 'moment'
import chatButton from '@/libs/chatButton'

export default {
  data() {
    return {
      chatButton,
      loading: false,
      items: null,
      text: '',
      search: '',
      dataPartner: {},
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      provider.list(WAREHOUSE_PARTNER)
        .then(({ data }) => {
          this.loading = false
          if (data.length === 0) {
            this.items = null
            this.text = 'Belum ada partner yang berlangganan'
          } else {
            this.items = data
          }
        })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    stock(data) {
      this.dataPartner = data
      this.$router.push({ name: 'detail-stock', params: { id: data.id, datapartner: data } })
    },
    // eslint-disable-next-line func-names
    searching: _.debounce(function () {
      this.$http_kompack.get(`/v1/kompack/warehouse/partner?search=${this.search}&&limit=10&&offset=0`)
        .then(response => {
          this.items = response.data.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    }, 1000),
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },
  },
}
</script>
